<template>
  <div>
      <v-row>
        <v-col  cols="12" md="12">
          <v-select
              clearable
              :items="courseSubjects"
              return-object
              item-value="id"
              item-text="desc"
              label="Select Module (For Capstone Project Teams)"
              solo
              v-model="selectedCourse"
              @change="fetchTeamMembers"
              @click:clear="removeCourse"
          >
          </v-select>
        </v-col>
      </v-row>

      <div v-if="students.length > 0">
        <v-row>
          <p class="text-h5 text--primary ml-3">
            Please evaluate yourself and your peers based on the Criteria below.
          </p>
        </v-row>

        <v-row v-for="(student, idx) in students" :key="student.id">
          <v-col cols="12" md="12">
            <v-card
              class="mx-auto"
            >
              <v-card-title class="d-flex flex-row justify-space-between">
                <span>{{idx + 1}}. {{student.name}} </span>
                <v-chip
                  class="ma-2"
                  color="secondary"
                  v-if="student.myself"
                >
                  Self Assessment
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="accent"
                  v-else
                >
                  Peer Assessment
                </v-chip>
              </v-card-title>
              <v-card-subtitle>
                {{student.rollNumber}}
              </v-card-subtitle>

              <v-card-text v-for="criteria in student.criterias" :key="criteria.criteriaId">
                <v-sheet
                  color="white"
                  elevation="1"
                  height="75"
                  class="d-flex flex-row justify-space-between pa-2"
                >
                  <span class="d-flex flex-column">
                    <span class="d-flex flex-column text-subtitle-1"> 
                      {{ criteria.criteriaName}}
                    </span>

                    <span class="d-flex flex-column text-caption"> 
                      {{ criteriaDescription(criteria.criteriaDescription, student.myself) }}
                    </span>
                  </span>

                  <span style="width: 150px" class="mt-4">
                    <v-rating
                      hover
                      length="5"
                      size="28"
                      dense
                      @input="setMarks(student, criteria, $event)"
                      :value="parseInt(criteria.score)"
                      :readonly='student.readMode'
                    >
                    </v-rating>
                  </span>
                </v-sheet>
              </v-card-text>

              <v-sheet
                v-if="!student.myself"
                color="white"
                class="d-flex flex-row justify-space-between pa-2 pr-4"
              >
                <v-textarea
                  height="80"
                  class="pl-4 pr-4"
                  v-model="student.comment"
                  filled
                  label="Your Feeback"
                  placeholder="Your overall feedback please..."
                >
                </v-textarea>

                <v-btn
                  color="primary"
                  elevation="2"
                  @click="setComment(student)"
                  :disabled='student.readMode ? true : false'
                >
                  Save Comments
                </v-btn>
              </v-sheet>

            </v-card>
          </v-col>
        </v-row>
      </div>

  </div>
</template>

<script>
import { fetchTeamMembers, addGroupAssignmentScore, addGroupAssignmentComment } from '../../api/course'

export default {
  components: {
  },
  data() {
    return {
      selectedCourse: {},
      students: [],
      defaultSelected:1
    }
  },
  mounted(){
  },
  computed: {
    courseSubjects(){    
        let courseSubjects = [];
        
        for(let i = 0; i < this.$store.getters.getCourseSubjects.length; i++){
            let courseSubject = {}
            let current = this.$store.getters.getCourseSubjects[i]

            courseSubject['desc'] = `[${current.subject.subjectName}] ${current.subject.subjectDescription}`
            courseSubject['id'] = current.id

            courseSubjects.push(courseSubject)
        }
        
        return courseSubjects
    },
  },
  methods:{
    removeCourse(){
      this.students = []
    },
    log(text){
      console.log(text)
    },
    criteriaDescription(description, myself){

      let desc = description ? description : '';

      if(myself){
        return desc
      }

      return desc.replaceAll('I ', 'He/She ').replaceAll(' my', ' his/her ')
    },
    async fetchTeamMembers(){
      if( this.selectedCourse && this.selectedCourse.id ){
        try{
          const apiData = await fetchTeamMembers({courseSubjectId: this.selectedCourse.id })
          const cleanData = []

          for(let i = 0; i < apiData.data.length; i++){
            let apiRow = apiData.data[i]
            apiRow['criterias'] = JSON.parse(apiRow['criterias'])
            cleanData.push(apiRow)
          }
          
          this.students = cleanData
        }
        catch( e ){
          console.error(e)
        }
      }
    },
    async setMarks(teamMember, criteria, marks){

      if(!marks){
        return; //FIXME:: Later, make this more elegant.
      }

      try{
        await addGroupAssignmentScore({
          rollNumber: teamMember.rollNumber,
          marks: marks,
          courseSubjectId: this.selectedCourse.id,
          criteriaId: criteria.criteriaId
        })
      }
      catch( e ){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
        this.isProcessing = false
      }
    },
    async setComment(teamMember){
      if(!teamMember.comment || teamMember.comment.trim().length < 4){
        this.$toast.open({
          message: 'Please do fill in some meaningful comments. Thanks!',
          type: 'error',
        });
        return;
      }

      try{
        await addGroupAssignmentComment({
          rollNumber: teamMember.rollNumber,
          comment: teamMember.comment,
          courseSubjectId: this.selectedCourse.id
        });

        this.$toast.open({
          message: 'Thanks for your feedback!',
          type: 'success',
        });
      }
      catch( e ){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
        this.isProcessing = false
      }
    }
  },
}
</script>

